import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import JsonExcel from "vue-json-excel";

import './css/styles.css';
import "./vee-validate";

Vue.config.productionTip = false


/* Font Awesome setting */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGlobe, faCalendarPlus, faIdCard, faPersonWalkingArrowRight, faKey } from '@fortawesome/free-solid-svg-icons'
import { faUser, faCalendar } from '@fortawesome/free-regular-svg-icons'
library.add(faUser, faCalendar, faGlobe, faCalendarPlus, faIdCard, faPersonWalkingArrowRight, faKey)
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* datatable */
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import 'font-awesome/css/font-awesome.css';

/* confi Dataticker */
let locale = process.env.VUE_APP_I18N_LOCALE || 'es'

let localeConfig = () => ({
  es: {
    dow: 0,
    dir: 'ltr',
    displayFormat: vm => {
      switch (vm.type) {
        case 'date':
          return 'YYYY/MM/DD'
        case 'datetime':
          return 'YYYY/MM/DD HH:mm'
        case 'year':
          return 'YYYY'
        case 'month':
          return 'MM'
        case 'yearmonth':
          return 'YY/MM'
        case 'time':
          return 'HH:mm'
      }
    },
    lang: {
      label: 'ES',
      submit: 'Aceptar',
      cancel: 'cancelar',
      now: 'Hoy',
      nextMonth: 'Mes Siguiente',
      prevMonth: 'Mes Anterior',
    }
  }
})

export const datePickerConfig = {
  name: 'datePicker',
  props: {
    locale,
    localeConfig,
    color: 'rgb(103,64, 146)',
    format: 'YYYY-MM-DD',
    displayFormat: 'jDD jMMMM jYYYY'
  }
}

Vue.use(VueCookies);
Vue.use(VuePersianDatetimePicker, datePickerConfig);
Vue.component("downloadExcel", JsonExcel);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
